/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import "./bootstrap.min.css"
import "./layout.css"
import StickyBottom from "../components/stickybottom"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import ReactGA from "react-ga"

ReactGA.initialize("UA-140819444-1")

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 960,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          <main>{children}</main>
          <footer className="py-3 mb-4">
            <StickyBottom />© {new Date().getFullYear()}, Copyrighted by
            {` `}
            <OutboundLink href="https://www.mega888cafe.com" className="link">
              918kiss Malaysia
            </OutboundLink>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
